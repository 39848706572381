export const RECURRINGINVOICE_CREATE_REQUEST =
  "RECURRINGINVOICE_CREATE_REQUEST";
export const RECURRINGINVOICE_CREATE_SUCCESS =
  "RECURRINGINVOICE_CREATE_SUCCESS";
export const RECURRINGINVOICE_GET_INVOICELIST_PAGE =
  " RECURRINGINVOICE_GET_INVOICELIST_PAGE";

export const RECURRINGINVOICE_CREATE_FAIL = "RECURRINGINVOICE_CREATE_FAIL";
export const RECURRINGINVOICE_CREATE_RESET = "RECURRINGINVOICE_CREATE_RESET";
export const RECURRINGINVOICE_LIST_REQUEST = "RECURRINGINVOICE_LIST_REQUEST";
export const RECURRINGINVOICE_LIST_SUCCESS = "RECURRINGINVOICE_LIST_SUCCESS";
export const RECURRINGINVOICE_LIST_FAIL = "RECURRINGINVOICE_LIST_FAIL";
export const RECURRINGINVOICE_EDIT_DETAIL_REQUEST =
  "RECURRINGINVOICE_EDIT_DETAIL_REQUEST";
export const RECURRINGINVOICE_EDIT_DETAIL_SUCCESS =
  "RECURRINGINVOICE_EDIT_DETAIL_SUCCESS";
export const RECURRINGINVOICE_EDIT_DETAIL_FAIL =
  "RECURRINGINVOICE_EDIT_DETAIL_FAIL";
export const RECURRINGINVOICE_EDIT_DETAIL_RESET =
  "RECURRINGINVOICE_EDIT_DETAIL_RESET";
export const RECURRINGINVOICE_EDIT_REQUEST = "RECURRINGINVOICE_EDIT_REQUEST";
export const RECURRINGINVOICE_EDIT_SUCCESS = "RECURRINGINVOICE_EDIT_SUCCESS";
export const RECURRINGINVOICE_EDIT_FAIL = "RECURRINGINVOICE_EDIT_FAIL";
export const RECURRINGINVOICE_EDIT_RESET = "RECURRINGINVOICE_EDIT_RESET";

export const AP_RECURRINGINVOICE_LIST_REQUEST =
  "AP_RECURRINGINVOICE_LIST_REQUEST";
export const AP_RECURRINGINVOICE_LIST_SUCCESS =
  "AP_RECURRINGINVOICE_LIST_SUCCESS";
export const AP_RECURRINGINVOICE_LIST_FAIL = "AP_RECURRINGINVOICE_LIST_FAIL";

export const AP_RECURRINGINVOICE_CREATE_REQUEST =
  "AP_RECURRINGINVOICE_CREATE_REQUEST";
export const AP_RECURRINGINVOICE_CREATE_SUCCESS =
  "AP_RECURRINGINVOICE_CREATE_SUCCESS";
export const AP_RECURRINGINVOICE_CREATE_FAIL =
  "AP_RECURRINGINVOICE_CREATE_FAIL";
export const AP_RECURRINGINVOICE_CREATE_RESET =
  "AP_RECURRINGINVOICE_CREATE_RESET";

export const AP_RECURRINGINVOICE_EDIT_DETAIL_REQUEST =
  "AP_RECURRINGINVOICE_EDIT_DETAIL_REQUEST";
export const AP_RECURRINGINVOICE_EDIT_DETAIL_SUCCESS =
  "AP_RECURRINGINVOICE_EDIT_DETAIL_SUCCESS";
export const AP_RECURRINGINVOICE_EDIT_DETAIL_FAIL =
  "AP_RECURRINGINVOICE_EDIT_DETAIL_FAIL";
export const AP_RECURRINGINVOICE_EDIT_DETAIL_RESET =
  "AP_RECURRINGINVOICE_EDIT_DETAIL_RESET";

export const AP_RECURRINGINVOICE_EDIT_REQUEST =
  "AP_RECURRINGINVOICE_EDIT_REQUEST";
export const AP_RECURRINGINVOICE_EDIT_SUCCESS =
  "AP_RECURRINGINVOICE_EDIT_SUCCESS";
export const AP_RECURRINGINVOICE_EDIT_FAIL = "AP_RECURRINGINVOICE_EDIT_FAIL";
export const AP_RECURRINGINVOICE_EDIT_RESET = "AP_RECURRINGINVOICE_EDIT_RESET";

export const AP_RECURRINGINVOICE_SCHEDULE_REQUEST =
  "AP_RECURRINGINVOICE_SCHEDULE_REQUEST";
export const AP_RECURRINGINVOICE_SCHEDULE_SUCCESS =
  "AP_RECURRINGINVOICE_SCHEDULE_SUCCESS";
export const AP_RECURRINGINVOICE_SCHEDULE_FAIL =
  "AP_RECURRINGINVOICE_SCHEDULE_FAIL";
export const AP_RECURRINGINVOICE_SCHEDULE_RESET =
  "AP_RECURRINGINVOICE_SCHEDULE_RESET";

export const AR_RECURRINGINVOICE_SCHEDULE_REQUEST =
  "AR_RECURRINGINVOICE_SCHEDULE_REQUEST";
export const AR_RECURRINGINVOICE_SCHEDULE_SUCCESS =
  "AR_RECURRINGINVOICE_SCHEDULE_SUCCESS";
export const AR_RECURRINGINVOICE_SCHEDULE_FAIL =
  "AR_RECURRINGINVOICE_SCHEDULE_FAIL";
export const AR_RECURRINGINVOICE_SCHEDULE_RESET =
  "AR_RECURRINGINVOICE_SCHEDULE_RESET";
