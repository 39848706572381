/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import "../../../_components/antd.css";
import { message, Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../../../_components/paginationfunction";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import {
  arRecurringSchedulerApi,
  getRecurringInvoiceList,
  setPage,
} from "../../../redux/actions/RecurringAction";
import { useSelector } from "react-redux";
import { getCustomerList } from "../../../redux/actions/commonApiAction";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
  dayList,
  intervalList,
} from "../constant";
import { formatDateYYMMDD } from "../../../utils/utils";
import { sendArApproval } from "../../../redux/actions/ARmoduleAction";
import { INVOICE_SUBMIT_ERP_RESET } from "../../../redux/constants/ARmoduleConstant";
import SchedulerModal from "../schedulerModal";
import { AR_RECURRINGINVOICE_SCHEDULE_RESET } from "../../../redux/constants/RecurringInvoiceConstant";
import DateRangePickerComponent from "../../../customers/dateRange";

const RecurringInvoice = () => {
  const [menu, setMenu] = useState(false);
  // const [file, setFile] = useState([]);

  const [filterData, setFilterData] = useState({
    customer_name: null,
    start_date: "",
    end_date: "",
    interval: null,
    day: null,
    nature_of_supply: "",
  });
  const [show, setShow] = useState(false);

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [messageApi, contextHolder] = message.useMessage();

  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;
  const recurringInvoiceList = useSelector(
    (state) => state.recurringInvoiceList
  );
  const {
    loading: recurringInvoiceListLoading,
    recurringList,
    page,
    perPage,
  } = recurringInvoiceList;

  const userData = useSelector((state) => state.userSignin);
  const { permissions } = userData;

  const arModuleSubmitErp = useSelector((state) => state?.arModuleSubmitErp);
  const { loading, success, error } = arModuleSubmitErp;

  const arRecurringInvoiceScheduler = useSelector(
    (state) => state?.arRecurringInvoiceScheduler
  );
  const {
    loading: schedulerLoading,
    error: schedulerError,
    success: schedulerSuccess,
  } = arRecurringInvoiceScheduler;

  const dispatch = useDispatch();

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSelectFilterChange = (selectedOption, id) => {
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
    dispatch(setPage(1, 10));
  };

  const handleDateFilterChange = (start, end) => {
    setFilterData((prevState) => ({
      ...prevState,
      start_date: start,
      end_date: end,
    }));
    dispatch(setPage(1, 10));
  };


  const handleScheduler = (event) => {
    event.preventDefault();
    dispatch(arRecurringSchedulerApi());
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  useEffect(() => {
    if (schedulerSuccess) {
      handleModalClose("#invoice_scheduler");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Scheduled invoice(s) generated successfully",
        duration: 5,
      });
      dispatch({ type: AR_RECURRINGINVOICE_SCHEDULE_RESET });
    }
  });

  const columns = [
    {
      title: "Sno.",
      dataIndex: "index",
      render: (text, record, index) => perPage * (page - 1) + index + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      sorter: (a, b) => a?.customer - b?.customer,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
    },
    {
      title: "Interval",
      dataIndex: "schedule_type",
      sorter: (a, b) => a.schedule_type.localeCompare(b.schedule_type),
    },

    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      sorter: (a, b) => a.invoice_amount - b.invoice_amount,
    },
    {
      title: "Tax Amount",
      dataIndex: "tax_amount",
      sorter: (a, b) => a.tax_amount - b.tax_amount,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {permissions?.arModule?.addEditTransactions && (
            <>
              <Link
                to={`/ar-module/recurring-edit-invoice/${record?.id}`}
                className="btn btn-primary me-2"
              >
                <i className="far fa-edit" />
              </Link>
            </>
          )}
        </>
      ),
    },
  ];

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  useEffect(() => {
    console.log("filterData:", filterData);
    dispatch(
      getRecurringInvoiceList(
        page,
        perPage,
        filterData?.customer_name ? filterData?.customer_name?.value : "",
        filterData?.start_date ? formatDateYYMMDD(filterData?.start_date) : "",
        filterData?.end_date ? formatDateYYMMDD(filterData?.end_date) : "",
        filterData?.interval ? filterData?.interval?.value : "",
        filterData?.day ? filterData?.day?.value : "",
        filterData?.nature_of_supply
      )
    );
    dispatch(getCustomerList());
  }, [page, perPage, filterData]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>AR module</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Recurring Invoice List
                    </li>
                  </ul>
                </div>
                {permissions?.arModule?.addEditTransactions && (
                  <div className="list-btn">
                    <ul className="filter-list filterbutton">
                      <li>
                        <Link
                          className="btn btn-greys  me-2"
                          data-bs-toggle="modal"
                          to="#invoice_scheduler"
                          role="button"
                          style={{ backgroundColor: "#E7BD17" }}
                        >
                          Run Scheduler Now (Test Mode)
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          className="btn btn-primary"
                          to={"/ar-module/recurring-add-invoice"}
                        >
                          <i
                            className="fa fa-plus-circle me-2"
                            aria-hidden="true"
                          />
                          Schedule Recurring Invoice
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <Select
                      options={[
                        { value: "", label: "All" },
                        ...(customer?.map((option) => ({
                          value: option.name,
                          label: option.name,
                        })) || []),
                      ]}
                      placeholder={"Customer Name"}
                      value={filterData?.customer_name}
                      onChange={(selectedOption) =>
                        handleSelectFilterChange(
                          selectedOption,
                          "customer_name"
                        )
                      }
                    />
                  </li>

                  {/* <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="start_date"
                      placeholderText="Start Date"
                      selected={filterData?.start_date}
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "start_date")
                      }
                    />
                    {filterData?.start_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() =>
                          handleDateFilterChange(null, "start_date")
                        }
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </li>

                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="end_date"
                      placeholderText="End Date"
                      selected={filterData?.end_date}
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "end_date")
                      }
                    />
                    {filterData?.end_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() => handleDateFilterChange(null, "end_date")}
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </li> */}
                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DateRangePickerComponent
                      startDate={filterData?.start_date || null}
                      endDate={filterData?.end_date || null}
                      handleDateChange={handleDateFilterChange} // Directly pass the handler
                    />
                    {(filterData?.start_date || filterData?.end_date) && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() => handleDateFilterChange(null, null)} // Clear both dates
                        aria-label="Clear Date Range"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </li>

                  <li className="customDrpdwn">
                    <Select
                      options={formattedOptions(intervalList)}
                      placeholder={"Interval"}
                      value={filterData?.interval}
                      onChange={(selectedOption) =>
                        handleSelectFilterChange(selectedOption, "interval")
                      }
                    />
                  </li>
                  {/* <li className="customDrpdwn">
                    <Select
                      options={formattedOptions(dayList)}
                      placeholder={"Day"}
                      value={filterData?.day}
                      onChange={(selectedOption) =>
                        handleSelectFilterChange(selectedOption, "day")
                      }
                    />
                  </li> */}
                  <li className="customDrpdwn">
                    <input
                      id="nature_of_supply"
                      type="text"
                      placeholder="Nature of Supply"
                      value={filterData?.nature_of_supply}
                      className="form-control"
                      onChange={handleFilterChange}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table armoduleList"
                        loading={recurringInvoiceListLoading}
                        pagination={{
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          total: recurringList?.count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={recurringList?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Other modals and components */}

        <SchedulerModal
          show={show}
          schedulerError={schedulerError}
          handleSubmit={handleScheduler}
          loading={schedulerLoading}
        />
      </div>
    </>
  );
};

export default RecurringInvoice;
