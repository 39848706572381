/* eslint-disable */
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import { message as antdMessage } from "antd";
import Sidebar from "../layouts/Sidebar";
import { Plan1, Plan2, Plan3 } from "../_components/Imagepath";
import {
  getSubscrptionHistoryList,
  getSubscrptionPlanList,
} from "./membershipAction";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import Plans from "./subscriptionPlan";
import PricingPlans from "./PricingPlans";

const MembershipPlan = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const getSubscriptionHistoryList = useSelector(
    (state) => state?.getSubscriptionHistoryList
  );
  const {
    loading: HistoryListLoading,
    listData: listHistoryData,
  } = getSubscriptionHistoryList;

  const getSubscriptionPlanList = useSelector(
    (state) => state?.getSubscriptionPlanList
  );
  const {
    loading: PlanListLoading,
    listData: listPlanData,
  } = getSubscriptionPlanList;

  const historyColumns = [
    {
      title: "Plan",
      align: "center",
      dataIndex: "plan_name",
      sorter: (a, b) => a.plan_name.length - b.plan_name.length,
    },
    {
      title: "Date",
      dataIndex: "subscribe_date",
      sorter: (a, b) => a.subscribe_date.length - b.subscribe_date.length,
    },
    {
      title: "Validity",
      dataIndex: "validity",
      sorter: (a, b) => a?.validity?.length - b?.validity?.length,
    },
    {
      title: "Amount",
      dataIndex: "price",
      sorter: (a, b) => a?.price?.length - b?.price?.length,
    },
  ];

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    dispatch(getSubscrptionHistoryList());
    dispatch(getSubscrptionPlanList());
  }, [dispatch]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <PricingPlans
              planData={listPlanData}
              loading={PlanListLoading}
              onPlanUpdate={() => {
                // Refresh the plans data after update
                dispatch(getSubscrptionPlanList());
                dispatch(getSubscrptionHistoryList());
              }}
              messageApi={messageApi}
            />
            <div className="subHistory">
              <h5>Subscription Invoice History</h5>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-table">
                    <div className="card-body payments">
                      <div className="table-responsive ">
                        <Table
                          loading={HistoryListLoading}
                          pagination={{
                            total: listHistoryData?.count,
                            // current: page,
                            // pageSize: perPage,
                            // onChange: handleTableChange,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            // showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          columns={historyColumns}
                          dataSource={listHistoryData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipPlan;
