/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const CommonDatePicker = React.forwardRef(
  ({ value, onChange, name, format = "YYYY-MM-DD", picker = "date", errors, customError, style = {}, ...rest }, ref) => {
    return (
      <div className="cal-icon cal-icon-info" style={{ width: "100%" }}>
        <DatePicker
          value={value ? dayjs(value) : null} // Ensure `dayjs` object
          onChange={(date) => onChange(name, date)} // Pass `name` for context
          format={format} // Set the date format
          picker={picker} // Date picker type (date, month, etc.)
          style={{ width: "100%", ...style }} // Merge default and custom styles
          ref={ref} // Attach ref if needed
          {...rest} // Spread any additional props
        />
        {/* {errors?.[name]?.message && (
          <p className="text-danger">{errors[name].message}</p>
        )}
        {customError && (
          <p className="text-danger">{customError}</p>
        )} */}
      </div>
    );
  }
);

// Define prop types for better validation
CommonDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  picker: PropTypes.string,
  errors: PropTypes.object,
  customError: PropTypes.string,
  style: PropTypes.object,
};

// Set default props
CommonDatePicker.defaultProps = {
  format: "YYYY-MM-DD",
  picker: "date",
  style: {},
  errors: null,
  customError: "",
};

export default CommonDatePicker;
