/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  customerEditDetail,
  updateCustomerDetail,
} from "../../redux/actions/CustomerModuleAction";
import { CUSTOMER_EDIT_RESET } from "../../redux/constants/CustomerModuleCnstant";
import { message, Spin } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { customerFieldOrder } from ".";
import CustomerAddEditModal from "./customerAddEditModal";
import { phoneNumberRegexValidation } from "../../utils/utils";

const schema = yup.object({
  name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  contact_name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  location: yup
    .string()
    .required("Please fill this field")
    .trim(),
  email: yup
    .string()
    .required("Please fill this field")
    .trim(),
  state: yup
    .string()
    .required("Please fill this field")
    .trim(),
  phone: yup
    .string()
    .required("Please fill this field")
    .trim(),
  address: yup
    .string()
    .required("Please fill this field")
    .trim(),
  zip_code: yup
    .string()
    .required("Please fill this field")
    .trim(),
});

const EditCustomer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isChanged, setIsChanged] = useState(false);

  const customerEditData = useSelector((state) => state.customerEditDetail);
  const {
    customerEditDetail: customerDetail,
    loading: customerDetailLoading,
  } = customerEditData;
  const customerDetailUpdate = useSelector((state) => state.customerEdit);
  const { loading, success, error } = customerDetailUpdate;
  const [apiError, setApiError] = useState({});

  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setApiError(error);
  }, [error]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    if (!phoneNumberRegexValidation(value) && id === "phone") {
      setApiError((prevState) => ({
        ...prevState,
        phone: "Invalid phone number. Please enter a valid phone number.",
      }));
    } else if (phoneNumberRegexValidation(value) && id === "phone") {
      setApiError((prevState) => ({
        ...prevState,
        phone: "",
      }));
    } else {
      setApiError((prevState) => ({ ...prevState, [id]: "" }));
    }
    setEditData((prevState) => ({ ...prevState, [id]: value }));
  };

  const inputValues = {
    name: editData?.name,
    contact_name: editData?.contact_name,
    email: editData?.email,
    gstin_number: editData?.gstin_number,
    pan_number: editData?.pan_number,
    state: editData?.state,
    phone: editData?.phone,
    address: editData?.address,
    zip_code: editData?.zip_code,
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });

  useEffect(() => {
    if (editData) {
      Object.keys(editData).forEach((field) => {
        setValue(field, editData[field]);
      });
    }
  }, [editData, setValue]);

  useEffect(() => {
    const firstErrorField = customerFieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      const inputElement = document.getElementById(firstErrorField);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const apiErrorField = customerFieldOrder.find(
      (field) => apiError && apiError[field]
    );
    if (apiErrorField) {
      const apiInputElement = document.getElementById(apiErrorField);
      if (apiInputElement) {
        apiInputElement.focus();
        setIsChanged(false);
      }
    }
  }, [apiError]);

  const handleEditSubmit = (data) => {
    const postData = {
      ...data,
      name: data?.name,
      contact_name: data?.contact_name,
      email: data?.email,
      gstin_number: editData?.gstin_number ? editData?.gstin_number : null,
      pan_number: editData?.pan_number ? editData?.pan_number : null,
      phone: data?.phone,
      address: data?.address,
      state: data?.state,
      zip_code: data?.zip_code,
      customer_code: editData?.customer_code ? editData?.customer_code : null,
      location: data?.location,
    };
    dispatch(updateCustomerDetail(postData, id));
    setIsChanged(false);
  };

  useEffect(() => {
    dispatch(customerEditDetail(id));
  }, [id]);

  useEffect(() => {
    if (success) {
      dispatch({ type: CUSTOMER_EDIT_RESET });
      dispatch(customerEditDetail(id));
      messageApi.open({
        type: "success",
        content: "Customer Detail updated successfully",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    if (customerDetail) {
      setEditData(customerDetail);
    }
  }, [customerDetail]);
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <div className="col-md-6">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/chartofaccount">Chart of Accounts</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          <a>Edit Customer</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                {customerDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <div className="form-group-item">
                          <h5 className="form-title">Basic Details</h5>
                          <CustomerAddEditModal
                            handleSubmit={handleSubmit}
                            handleEditSubmit={handleEditSubmit}
                            control={control}
                            handleChange={handleChange}
                            errors={errors}
                            apiError={apiError}
                            isChanged={isChanged}
                            loading={loading}
                            addCustomer={editData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditCustomer;
