/* eslint-disable */
const datatable = (tableID) => {
  $(tableID).DataTable({
    bFilter: false,
    paging: true,
  });
};

export default datatable;

export const REGEX_INDIA_PHONE = /^[+]?[0-9]{12}$/;
export const REGEX_PHONE_NUMBER = /^[0-9]{10}$/;

export const formatDateYYMMDD = (dateString) => {
  const date = new Date(dateString);

  // Get the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const phoneNumberRegexValidation = (phone_number, isIndiaPhone) => {
  const phoneNumberRegex = isIndiaPhone
    ? REGEX_INDIA_PHONE
    : REGEX_PHONE_NUMBER;
  return phoneNumberRegex.test(phone_number);
};

export const applyIndiaPhoneCode = (inputValue) => {
  // Remove any non-numeric characters except for the initial +91
  let phone_number = inputValue.replace(/[^0-9]/g, "");
  // Always prepend +91 to the input, but allow backspacing after it
  if (phone_number.length >= 2) {
    return (phone_number = "+91" + phone_number.substring(2));
  } else {
    return (phone_number = "+91"); // Keep +91 if backspacing below length 3
  }
};