import {
  GET_INVOICELIST_FAIL,
  GET_INVOICELIST_REQUSET,
  GET_INVOICELIST_SUCCESS,
  INVOICE_CREATE_REQUEST,
  INVOICE_CREATE_SUCCESS,
  INVOICE_CREATE_FAIL,
  INVOICE_CREATE_RESET,
  INVOICE_EDIT_DETAIL_REQUEST,
  INVOICE_EDIT_DETAIL_SUCCESS,
  INVOICE_EDIT_DETAIL_FAIL,
  INVOICE_EDIT_REQUEST,
  INVOICE_EDIT_SUCCESS,
  INVOICE_EDIT_FAIL,
  INVOICE_EDIT_RESET,
  GET_INVOICELIST_PAGE,
  INVOICE_SUBMIT_ERP_REQUEST,
  INVOICE_SUBMIT_ERP_SUCCESS,
  INVOICE_SUBMIT_ERP_FAIL,
  INVOICE_SUBMIT_ERP_RESET,
  INVOICE_EDIT_DETAIL_RESET,
} from "../constants/ARmoduleConstant";

const initialState = {
  arInvoice: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const invoiceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICELIST_REQUSET:
      return { ...state, loading: true, error: null };
    case GET_INVOICELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        arInvoice: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_INVOICELIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_INVOICELIST_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const invoiceEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case INVOICE_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case INVOICE_EDIT_DETAIL_SUCCESS:
      return { loading: false, invoiceEditDetail: action.payload };
    case INVOICE_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_EDIT_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const invoiceAddReducer = (state = {}, action) => {
  switch (action.type) {
    case INVOICE_CREATE_REQUEST:
      return { loading: true };

    case INVOICE_CREATE_SUCCESS:
      return { loading: false, success: true };

    case INVOICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const invoiceEditReducer = (state = {}, action) => {
  switch (action.type) {
    case INVOICE_EDIT_REQUEST:
      return { loading: true };

    case INVOICE_EDIT_SUCCESS:
      return { loading: false, success: true };

    case INVOICE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const sendArApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case INVOICE_SUBMIT_ERP_REQUEST:
      return { loading: true };
    case INVOICE_SUBMIT_ERP_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case INVOICE_SUBMIT_ERP_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_SUBMIT_ERP_RESET:
      return {};
    default:
      return state;
  }
};
