import { HTTP } from "../api";
import { UPDATE_PAYMENT_SUBSCRIPTION_FAIL, UPDATE_PAYMENT_SUBSCRIPTION_REQUEST, UPDATE_PAYMENT_SUBSCRIPTION_SUCCESS } from "../constants/SubscriptionPlanConstant";

export const updateSubscriptionPlanApi = (payload) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_PAYMENT_SUBSCRIPTION_REQUEST, payload: payload });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await HTTP.patch(`/payment/subscription-update/`, payload, {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      });
      dispatch({ type: UPDATE_PAYMENT_SUBSCRIPTION_SUCCESS, payload: {data} });
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_SUBSCRIPTION_FAIL,
        payload:
          error.response && error?.response?.data
            ? error?.response?.data
            : error.message,
      });
    }
  };