import { useEffect } from "react";

const useUpdateFooterData = (
  footerData,
  setFooterData,
  totals,
  gstSection,
  company,
  addData
) => {
  const {
    totalBaseAmount,
    totalAmount,
    totalTdsAmount,
    totalGstAmount,
  } = totals;

  useEffect(() => {
    if (addData?.vendor_gstin) {
      if (gstSection?.length === 1) {
        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            [gstSection[0]]: totalGstAmount.toFixed(2),
          },
          total_tax: totalGstAmount.toFixed(2),
        }));
      } else if (gstSection?.length > 1) {
        const gstCalculation = totalGstAmount / 2;

        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            [gstSection[0]]: gstCalculation?.toFixed(2),
            [gstSection[1]]: gstCalculation?.toFixed(2),
          },
          total_tax: totalGstAmount?.toFixed(2),
        }));
      }
    }
  }, [totalGstAmount, gstSection, addData?.vendor_gstin]);

  useEffect(() => {
    if (addData?.vendor_gstin && company?.gstin_number) {
      console.log(addData?.vendor_gstin, "GstinCheck");

      if (
        company?.gstin_number?.slice(0, 2) ===
        addData?.vendor_gstin?.slice(0, 2)
      ) {
        const gstCalculation = totalGstAmount / 2;
        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            ["CGST Input"]: gstCalculation.toFixed(2),
            ["SGST Input"]: gstCalculation.toFixed(2),
          },
          total_tax: totalGstAmount.toFixed(2),
        }));
      } else if (
        company?.gstin_number?.slice(0, 2) !==
        addData?.vendor_gstin?.slice(0, 2)
      ) {
        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            ["IGST Input"]: totalGstAmount.toFixed(2),
          },
          total_tax: totalGstAmount.toFixed(2),
        }));
      }
    } 
    else if (addData?.gstin_number && company?.gstin_number) {
      if (
        company?.gstin_number?.slice(0, 2) ===
        addData?.gstin_number?.slice(0, 2)
      ) {
        const gstCalculation = totalGstAmount / 2;
        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            ["CGST Output"]: gstCalculation.toFixed(2),
            ["SGST Output"]: gstCalculation.toFixed(2),
          },
          total_tax: totalGstAmount.toFixed(2),
        }));
      } else if (
        company?.gstin_number?.slice(0, 2) !==
        addData?.gstin_number?.slice(0, 2)
      ) {
        setFooterData((prevState) => ({
          ...prevState,
          gst_section: {
            ["IGST Output"]: totalGstAmount.toFixed(2),
          },
          total_tax: totalGstAmount.toFixed(2),
        }));
      }
    }
    // else {
    //   setFooterData((prevState) => ({
    //     ...prevState,
    //     gst_section: {},
    //     total_tax: 0,
    //   }));

    // }
  }, [totalGstAmount, company, addData?.vendor_gstin, addData?.gstin_number]);

  useEffect(() => {
    const amountPayable = totalAmount - totalTdsAmount;
    setFooterData((prevState) => ({
      ...prevState,
      amount_payable: amountPayable.toFixed(2),
    }));
  }, [totalAmount, totalTdsAmount]);

  useEffect(() => {
    setFooterData((prevState) => ({
      ...prevState,
      total_base_amount: totalBaseAmount.toFixed(2),
      total_amount: totalAmount.toFixed(2),
      total_tds_amount: totalTdsAmount.toFixed(2),
    }));
  }, [totalBaseAmount, totalAmount, totalTdsAmount]);
};

export default useUpdateFooterData;
