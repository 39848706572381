export const invoiceDetails = [
  {
    id: 1,
    posting_ledger: "",
    description: "",
    taxonomy_codes: "",
    unit: "",
    rate: "",
    gst_rate: "",
    amount: "",
  },
  {
    id: 2,
    posting_ledger: "",
    description: "",
    taxonomy_codes: "",
    unit: "",
    rate: "",
    gst_rate: "",
    amount: "",
  },
  {
    id: 3,
    posting_ledger: "",
    description: "",
    taxonomy_codes: "",
    unit: "",
    rate: "",
    gst_rate: "",
    amount: "",
  },
];

export const containerStyle = {
  position: "relative",
  display: "inline-block",
};

export const clearButtonStyle = {
  position: "absolute",
  top: "50%",
  right: "10px",
  transform: "translateY(-50%)",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  color: "#999",
  fontSize: "1.2rem",
  zIndex: 1,
};

export const clearButtonHoverStyle = {
  color: "#333",
};

export const intervalList = [
  { id: "", text: "Select Interval" },
  { id: "monthly", text: "Monthly" },
  { id: "quarterly", text: "Quarterly" },
  { id: "weekly", text: "Weekly" },
  { id: "yearly", text: "Yearly" },
];

export const availabilityChoices = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

export const dayList = [
  { id: "", text: "Select Day" },
  { id: "SUNDAY", text: "Sunday" },
  { id: "MONDAY", text: "Monday" },
  { id: "TUESDAY", text: "Tuesday" },
  { id: "WEDNESDAY", text: "Wednesday" },
  { id: "THURSDAY", text: "Thursday" },
  { id: "FRIDAY", text: "Friday" },
  { id: "SATURDAY", text: "Saturday" },
];

export const initialLedgerData = [
  {
    description: "",
    quantity: "",
    rate: "",
    tax_rate: "",
    amount: "",
    product_code: "",
  },
];

export const weekDays = [
  { label: "Mon", value: 0 },
  { label: "Tue", value: 1 },
  { label: "Wed", value: 2 },
  { label: "Thu", value: 3 },
  { label: "Fri", value: 4 },
  { label: "Sat", value: 5 },
  { label: "Sun", value: 6 },
];

export const nthWeekDayOptions = [
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
  { label: "last", value: 5 },
];

export const scheduleTypeData = {
  monthly: "month",
  weekly: "weeks",
  quarterly: "quarter",
  yearly: "year",
};

export const quarterMonthChoices = {
  1: "1st month",
  2: "2nd month",
  3: "3rd month",
};

export const months = [
  { text: "Jan", id: "Jan" },
  { text: "Feb", id: "Feb" },
  { text: "Mar", id: "Mar" },
  { text: "Apr", id: "Apr" },
  { text: "May", id: "May" },
  { text: "Jun", id: "Jun" },
  { text: "Jul", id: "Jul" },
  { text: "Aug", id: "Aug" },
  { text: "Sep", id: "Sep" },
  { text: "Oct", id: "Oct" },
  { text: "Nov", id: "Nov" },
  { text: "Dec", id: "Dec" },
];

export const scheduleTypes = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Yearly", value: "yearly" },
];
