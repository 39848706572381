import { UPDATE_PAYMENT_SUBSCRIPTION_FAIL, UPDATE_PAYMENT_SUBSCRIPTION_REQUEST, UPDATE_PAYMENT_SUBSCRIPTION_RESET, UPDATE_PAYMENT_SUBSCRIPTION_SUCCESS } from "../constants/SubscriptionPlanConstant";

export const subscriptionPlanEditReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_SUBSCRIPTION_REQUEST:
      return { loading: true };

    case UPDATE_PAYMENT_SUBSCRIPTION_SUCCESS:
      return { loading: false, success: true,responseData:action.payload.data };

    case UPDATE_PAYMENT_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PAYMENT_SUBSCRIPTION_RESET:
      return {};
    default:
      return state;
  }
};