/* eslint-disable */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
} from "../sales/invoices/constant";
import DateRangePickerComponent from "./dateRange";
import { RefreshCw } from "lucide-react";
import CommonDatePicker from "../sales/invoices/customDatePicker";

const APModuleHeader = ({
  filterData,
  setFilterData,
  userListData,
  handleSelectFilterChange,
  handleDateChange,
  permissions,
  isHovered,
  setIsHovered,
  handleMouseEnter,
  handleMouseLeave,
  setRefresh,
  refresh,
  handleDateFilterChange,
}) => {
  const statusOptions = [
    { id: "", text: "All" },
    { id: "Pending", text: "Pending" },
    { id: "ready to post", text: "Ready to Post" },
    { id: "Rejected", text: "Rejected" },
  ];

  const approvalStatusOptions = [
    { id: "", text: "All" },
    { id: "pending approval", text: "Pending Approval" },
    { id: "user approved", text: "User Approved" },
    { id: "rejected approval", text: "Rejected Approval" },
  ];

  return (
    <>
      <div className="content-page-header">
        <div className="col">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a>AP module</a>
            </li>
            <li className="breadcrumb-item active">Invoice List</li>
          </ul>
        </div>
        {permissions?.apModule?.submitInvoice && (
          <div className="list-btn">
            <ul className="filter-list filterbutton apmoduleHeader">
              <li>
                <Link
                  className="btn btn-primary  me-2 "
                  to="/ap-module/add-invoice/"
                  role="button"
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Create Invoice
                </Link>
              </li>
              &nbsp;
              <li>
                <Link
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  to="#upload_invoice"
                  role="button"
                >
                  <i className="fa fa-upload me-2" aria-hidden="true" />
                  Upload Invoice
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="list-btn">
        <ul className="filter-list" style={{ position: "relative" }}>
          <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <DateRangePickerComponent
              startDate={filterData.startDate}
              endDate={filterData.endDate}
              handleDateChange={handleDateChange}
            />
          </li>
          <li>
            <div
              style={containerStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <CommonDatePicker
                name="invoice_date" // Field name
                value={filterData?.invoice_date} // Current value
                placeholder="Invoice Date" // Placeholder text
                onChange={(name, date) => handleDateFilterChange(date, name)} // Pass the name and date
                format="YYYY-MM-DD" // Date format
                picker="date" // Regular date picker
                style={{ width: "100%" }} // Custom styling
              />
            </div>
          </li>
          <li className="customDrpdwn">
            <Select
              options={[
                { value: "", label: "All" },
                ...((userListData?.results?.length &&
                  userListData?.results?.map((option) => ({
                    value: option.id,
                    label: option.username,
                  }))) ||
                  []),
              ]}
              placeholder="Select User"
              value={filterData?.user}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "user")
              }
            />
          </li>

          <li className="customDrpdwn">
            <Select
              options={statusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "status")
              }
              placeholder="Select Status"
            />
          </li>

          <li className="customDrpdwn">
            <Select
              options={approvalStatusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.approval_status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "approval_status")
              }
              placeholder="Select Approval Status"
            />
          </li>
          <li
            className="refresh-icon"
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              color: "#7539FF",
            }}
          >
            <RefreshCw
              style={{ cursor: "pointer" }}
              onClick={() => setRefresh(!refresh)}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

APModuleHeader.propTypes = {
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  userListData: PropTypes.object,
  handleSelectFilterChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default APModuleHeader;
