/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import Select from "react-select";
import { tdsCalculationApi } from "../redux/actions/APmoduleAction";
import PropTypes from "prop-types";
import CustomSelect from "./customSelect";
import CustomInput from "../_components/inputField/custominput";
import { useSelector } from "react-redux";
import { message, Select, Spin } from "antd";
import AddLedgerModal from "./addLedgerModal";
import { setError } from "../redux/actions/commonApiAction";
import { POST_LEDGERLIST_RESET } from "../redux/constants/ledgerListModuleConstant";
import GroupedSelect from "../components/GroupSelect";

const TableEditInvoice = ({
  setData,
  data,
  expenseList,
  setItemId,
  setIsChanged,
  setEditable,
  editable,
  footerData,
  tdsSectionData,
  addLedgerData,
  setAddLedgerData,
  isModalVisible,
  setIsModalVisible,
  handleSubmit,
  handleEditSubmit,
  control,
  errors,
  handleModalClose,
  setApiError,
  apiError,
  handleTdsPopupScroll,
  ledgerListData,
  tdsListData,
  handlePopupScroll,
  tdsSectionListLoading,
  ledgerListLoading,
  setLedgerPage,
  setLedgerIndexData,
  ledgerIndexData,
  setLedgerData,
  getLedgerModuleListByGroup,
  editData,
  setShowErrors,
  setItemsError,
  itemsError,
}) => {
  const dispatch = useDispatch();
  const [calculationData, setCalculationData] = useState({});
  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const { loading, error, success, data: postLedgerData } = postLedgerList;

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (error) {
      setApiError(error);
    }
  }, [error]);

  useEffect(() => {
    if (success && postLedgerData) {
      handleModalClose("#add_ledger");
      dispatch({ type: POST_LEDGERLIST_RESET });
      const updatedData = [...data];
      updatedData[ledgerIndexData]["posting_ledger"] = postLedgerData?.id;
      setData(updatedData);
      dispatch(getLedgerModuleListByGroup());
      setIsChanged(true);
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [success, postLedgerData, ledgerIndexData]);

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      setIsModalVisible(true);
      setAddLedgerData({ title: "", group: null, description: "" });
      setError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", group: null, description: "" });
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  const handleSelectCellChange = (selectedOption, id, rowKey, index, row) => {
    const updatedData = [...data];

    updatedData[index][id] = selectedOption;

    if (id === "tds_ledger") {
      const item = {
        posting_ledger: row?.posting_ledger,
        tds_ledger: row?.tds_ledger,
        description: row?.description,
        gst_rate: row?.gst_rate ? row?.gst_rate : "",
        gst_amount: row?.gst_amount ? row?.gst_amount : "",
        product_code: row?.product_code,
        quantity: row?.quantity,
        amount: row?.amount,
        discount: row?.discount,
        rate: row?.rate,
        total: row?.total,
      };
      setData(updatedData);
      setItemId(index);
      const data = {
        tds_section_id: selectedOption,
        item: item,
      };

      dispatch(tdsCalculationApi(data));
      setIsChanged(true);
    }
    setData(updatedData);

    setIsChanged(true);
  };

  const handleCellChange = (rowId, field, value, index, row) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    const updatedError = [...itemsError];
    if (field === "rate" || field === "discount") {
      const rate = field === "rate" ? value : row.rate;
      const discount = field === "discount" ? value : row.discount;

      if (parseFloat(rate) < parseFloat(discount)) {
        if (field === "discount") {
          updatedError[index]["discount"] = [
            "Discount cannot exceed the Unit Price.",
          ];
          setShowErrors(true);
          setItemsError(updatedError);
        }
      } else if (field === "discount") {
        if (updatedError[index]) {
          delete updatedError[index]["discount"];
          setItemsError(updatedError);
        }
      }
    }
    if (
      (field === "quantity" ||
        field === "rate" ||
        field === "gst_rate" ||
        field === "discount") &&
      row?.tds_ledger &&
      row?.description
    ) {
      setItemId(index);

      const amount =
        (row?.rate && row?.quantity) ||
        (row?.rate && row?.quantity && row?.discount)
          ? !row?.discount
            ? parseFloat(row?.rate) * parseFloat(row?.quantity)
            : (parseFloat(row?.rate) - parseFloat(row?.discount)) *
              parseFloat(row?.quantity)
          : "";
      const gstAmount =
        row?.gst_rate && amount
          ? (amount * parseFloat(row?.gst_rate)) / 100
          : "";
      const total =
        (amount && gstAmount) || amount
          ? !gstAmount
            ? parseFloat(amount)
            : parseFloat(amount) + parseFloat(gstAmount)
          : "";
      const item = {
        posting_ledger: row?.posting_ledger,
        tds_ledger: row?.tds_ledger,
        description: row?.description,
        product_code: row?.product_code,
        gst_rate: row?.gst_rate ? parseFloat(row?.gst_rate)?.toFixed(2) : 0,
        quantity: row?.quantity ? parseFloat(row?.quantity)?.toFixed(2) : 0,
        gst_amount: gstAmount ? gstAmount?.toFixed(2) : 0,
        amount: amount ? amount?.toFixed(2) : 0,
        discount: row?.discount ? parseFloat(row?.discount)?.toFixed(2) : 0,
        rate: row?.rate ? parseFloat(row?.rate)?.toFixed(2) : 0,
        total: total ? total?.toFixed(2) : 0,
      };
      const postData = {
        item: item,
        tds_section_id: row?.tds_ledger,
      };
      dispatch(tdsCalculationApi(postData));
    }
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const discount =
          parseFloat(updatedData[calculationData?.indexId]["discount"]) || 0;
        // const amount = rate * quantity;
        const amount = rate && quantity ? (rate - discount) * quantity : 0;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const renderCell = (rowId, field, value, index, row) => {
    if (editable.rowId === rowId && editable.field === field) {
      if (field === "posting_ledger") {
        return (
          // <CustomSelect
          //   id={"posting_ledger"}
          //   value={value}
          //   onChange={(selectedOption) => {
          //     handleSelectCellChange(
          //       selectedOption,
          //       "posting_ledger",
          //       rowId,
          //       index
          //     );
          //   }}
          //   options={ledgerListData?.length ? ledgerListData : []}
          //   addLedgerData={addLedgerData}
          //   setAddLedgerData={setAddLedgerData}
          //   handleSubmit={handleSubmit}
          //   handleEditSubmit={handleEditSubmit}
          //   isModalVisible={isModalVisible}
          //   setIsModalVisible={setIsModalVisible}
          //   control={control}
          //   errors={errors}
          //   handleModalClose={handleModalClose}
          //   setApiError={setApiError}
          //   apiError={apiError}
          //   onPopupScroll={handlePopupScroll}
          //   ledgerListLoading={ledgerListLoading}
          // />
          <GroupedSelect
            id="posting_ledger"
            value={value}
            onChange={(selectedOption) => {
              handleSelectCellChange(
                selectedOption,
                "posting_ledger",
                rowId,
                index
              );
            }}
            options={ledgerListData?.length ? ledgerListData : []}
            suggestedEntry={true}
            row={row}
            index={index}
            setLedgerIndexData={setLedgerIndexData}
            // onPopupScroll={handleScroll}
            // loading={isLoading}
          />
        );
      } else if (field === "tds_ledger") {
        return (
          <Select
            value={value}
            style={{ width: 300 }}
            options={
              tdsListData?.length
                ? tdsListData?.map((option) => ({
                    value: option.id,
                    label: `${option.section_id} - ${option.section_name}`,
                  }))
                : []
            }
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            isDisabled={!row?.description}
            onChange={(selectedOption) => {
              handleSelectCellChange(
                selectedOption,
                "tds_ledger",
                rowId,
                index,
                row
              );
            }}
            onPopupScroll={handleTdsPopupScroll}
            dropdownRender={(menu) => (
              <>
                {menu}
                {tdsSectionListLoading && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 8,
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </>
            )}
          />
        );
      } else if (field === "tds_amount") {
        return (
          <input
            type={"text"}
            value={value}
            readOnly={!row?.description}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index, row)
            }
          />
        );
      } else {
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type={field === "description" ? "text" : "number"}
              value={value}
              onChange={(e) =>
                handleCellChange(rowId, field, e.target.value, index, row)
              }
              min={0}
              max={field === "discount" ? row?.rate : undefined}
              disabled={
                field === "amount" ||
                field === "gst_amount" ||
                field === "total" ||
                (field === "gst_rate" && !editData?.vendor_gstin)
              }
              onBlur={() => setEditable({})}
              autoFocus
            />
          </>
        );
      }
    }
    if (field === "posting_ledger") {
      return (
        // <CustomSelect
        //   id={"posting_ledger"}
        //   value={value}
        //   onChange={(selectedOption) => {
        //     handleSelectCellChange(
        //       selectedOption,
        //       "posting_ledger",
        //       rowId,
        //       index
        //     );
        //   }}
        //   options={ledgerListData?.length ? ledgerListData : []}
        //   addLedgerData={addLedgerData}
        //   setAddLedgerData={setAddLedgerData}
        //   handleSubmit={handleSubmit}
        //   handleEditSubmit={handleEditSubmit}
        //   isModalVisible={isModalVisible}
        //   setIsModalVisible={setIsModalVisible}
        //   control={control}
        //   errors={errors}
        //   handleModalClose={handleModalClose}
        //   setApiError={setApiError}
        //   apiError={apiError}
        //   suggestedEntry={true}
        //   row={row}
        //   onPopupScroll={handlePopupScroll}
        //   ledgerListLoading={ledgerListLoading}
        // />
        <GroupedSelect
          id="posting_ledger"
          value={value}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "posting_ledger",
              rowId,
              index
            );
          }}
          options={ledgerListData?.length ? ledgerListData : []}
          suggestedEntry={true}
          row={row}
          index={index}
          setLedgerIndexData={setLedgerIndexData}
          // onPopupScroll={handleScroll}
          // loading={isLoading}
        />
      );
    } else if (field === "tds_ledger") {
      //tdssss

      return (
        <Select
          value={value}
          options={tdsListData?.map((option) => ({
            value: option.id,
            label: `${option.section_id} - ${option.section_name}`,
          }))}
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase()?.includes(input?.toLowerCase())
          }
          style={{ width: 300 }}
          isDisabled={!row?.description}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "tds_ledger",
              rowId,
              index,
              row
            );
          }}
          onPopupScroll={handleTdsPopupScroll}
          dropdownRender={(menu) => (
            <>
              {menu}
              {tdsSectionListLoading && (
                <div
                  style={{
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Spin />
                </div>
              )}
            </>
          )}
        />
      );
    } else if (field === "tds_amount") {
      return (
        <input
          type={"text"}
          value={value}
          readOnly={!row?.description}
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index, row)
          }
        />
      );
    } else {
      return (
        <input
          type={field === "description" ? "text" : "number"}
          value={value}
          min={0}
          max={field === "discount" && row?.rate}
          disabled={
            field === "amount" ||
            field === "gst_amount" ||
            field === "total" ||
            (field === "gst_rate" && !editData?.vendor_gstin)
          }
          readOnly
          onDoubleClick={() => handleDoubleClick(rowId, field)}
        />
      );
    }
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        posting_ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        tds_ledger: null,
        tds_amount: "",
      },
    ]);
    setItemsError([...itemsError, {}]);
    setAddLedgerData({ title: "", group: null, description: "" });
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setIsChanged(true);
    setData(filterData);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleLedgerSelectChange = (id, selectedOption) => {
    setAddLedgerData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  return (
    <>
      {contextHolder}
      <div className="tableHscroll">
        <table className="apedittable">
          <thead>
            <tr>
              <>
                <td align="center">Sl No.</td>
                <td>Ledger Name</td>
                <td>Description</td>
                <td>Unit</td>
                <td>Unit Price</td>
                <td>Discount</td>
                <td>Base Amount</td>
                <td>GST Rate</td>
                <td>GST Amount</td>
                <td>Total</td>
                <td>Tds Ledger</td>
                <td>Tds Amount</td>
                <td>&nbsp;</td>
              </>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index}>
                <>
                  <td align="center">{index + 1}</td>
                  <td className="customtableSelect">
                    {renderCell(
                      row.id,
                      "posting_ledger",
                      row?.posting_ledger,
                      index,
                      row
                    )}
                  </td>
                  <td>
                    {renderCell(
                      row.id,
                      "description",
                      row.description,
                      index,
                      row
                    )}
                  </td>
                  <td>
                    {renderCell(row.id, "quantity", row.quantity, index, row)}
                  </td>
                  <td>{renderCell(row.id, "rate", row.rate, index, row)}</td>
                  <td>
                    {renderCell(row.id, "discount", row.discount, index, row)}
                  </td>
                  <td>
                    {renderCell(row.id, "amount", row.amount, index, row)}
                    {/* 12 */}
                  </td>

                  <td>
                    {renderCell(row.id, "gst_rate", row.gst_rate, index, row)}
                  </td>
                  <td>
                    {renderCell(
                      row.id,
                      "gst_amount",
                      row.gst_amount,
                      index,
                      row
                    )}
                  </td>
                  <td>{renderCell(row.id, "total", row.total, index, row)}</td>
                  <td className="customtableSelect">
                    {renderCell(
                      row.id,
                      "tds_ledger",
                      row.tds_ledger,
                      index,
                      row
                    )}
                  </td>
                  <td>
                    {renderCell(row.id, "tds_amount", row.tds_amount, index)}
                  </td>
                  <td className="tableEditBtns">
                    <button
                      className="btn btn-default btn-xs delete"
                      onClick={(event) => handleRemove(event, index)}
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </>
              </tr>
            ))}

            {/* Add the "+ Add New Row" button at the end of the table */}
            <tr>
              <td
                colSpan={13}
                style={{
                  textAlign: "right",
                  marginTop: "10px",
                }}
              >
                <button
                  className="btn btn-greys btn-xs add"
                  onClick={handleAddClick}
                >
                  <i className="far fa-add" />
                </button>
              </td>
            </tr>

            {/* Optional: Empty row for visual separation */}
            <tr>
              <td colSpan={13}></td>
            </tr>
            <tr className="rowStickty">
              <>
                <td colSpan={10}></td>
                <td align="left">
                  <strong>Base Amount</strong>
                </td>
                <td align="right">
                  {footerData?.total_base_amount
                    ? footerData?.total_base_amount
                    : 0}
                </td>
                <td>&nbsp;</td>
              </>
            </tr>

            {footerData?.gst_section &&
              Object.keys(footerData?.gst_section)?.map((el, index) => {
                return (
                  <tr key={index} className="rowStickty">
                    <>
                      <td colSpan={10}></td>
                      <td align="left">
                        <strong>{el}</strong>
                      </td>
                      <td align="right">
                        {footerData?.gst_section
                          ? footerData["gst_section"][el]
                          : ""}
                      </td>
                      <td>&nbsp;</td>
                    </>
                  </tr>
                );
              })}
            <tr className="rowStickty">
              <>
                <td colSpan={10}></td>
                <td align="left">
                  <strong>Total Tax</strong>
                </td>
                <td align="right">
                  {footerData?.total_tax ? footerData?.total_tax : 0}
                </td>
                <td>&nbsp;</td>
              </>
            </tr>
            <tr className="rowStickty">
              <>
                <td colSpan={10}></td>
                <td align="left">
                  <strong>Total Amount</strong>
                </td>
                <td align="right">{footerData?.total_amount}</td>
                <td>&nbsp;</td>
              </>
            </tr>
            <tr className="rowStickty">
              <>
                <td colSpan={10}></td>
                <td align="left">
                  <strong>Tds Total Amount</strong>
                </td>
                <td align="right">{footerData?.total_tds_amount}</td>
                <td>&nbsp;</td>
              </>
            </tr>
            <tr className="rowStickty">
              <>
                <td colSpan={10}></td>
                <td align="left">
                  <strong>Amount Payable</strong>
                </td>
                <td align="right">{footerData?.amount_payable}</td>
                <td>&nbsp;</td>
              </>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="tableHscroll">
        <table className="apedittableInvoice">
          <thead>
            <tr>
              <>
                <td colSpan="3">Debit</td>
                <td colSpan={3}>Credit</td>
              </>
            </tr>
          </thead>
          <tbody>
            <tr>
              <>
                <td colSpan={3}>
                  <table>
                    <thead>
                      <tr>
                        <>
                          <td>Date</td>
                          <td>Ledger Name</td>
                          <td>Amount</td>
                        </>
                      </tr>
                    </thead>
                    <tbody>
                      {footerData?.suggested_entry?.debit &&
                        footerData?.suggested_entry?.debit?.ledgers?.map(
                          (el, index) => {
                            return (
                              <tr key={index}>
                                <>
                                  <td>
                                    {index === 0
                                      ? footerData?.suggested_entry?.debit?.date
                                      : ""}
                                  </td>
                                  <td>{el?.ledger}</td>
                                  <td>{el?.amount}</td>
                                </>
                              </tr>
                            );
                          }
                        )}
                      <tr>
                        <>
                          <td></td>
                          <td>
                            <strong>Rates & Taxes</strong>
                          </td>
                          <td>
                            <strong>Tax Amount</strong>
                          </td>
                        </>
                      </tr>
                      {footerData?.suggested_entry?.debit?.gst &&
                        Object.keys(
                          footerData?.suggested_entry?.debit?.gst
                        )?.map((el, index) => {
                          return (
                            <tr key={index}>
                              <>
                                <td></td>
                                <td>{el}</td>
                                <td>
                                  {footerData?.suggested_entry?.debit?.gst
                                    ? footerData["suggested_entry"]["debit"][
                                        "gst"
                                      ][el]
                                    : ""}
                                </td>
                              </>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </td>
                <td colSpan={3}>
                  <table>
                    <thead>
                      <tr>
                        <>
                          <td>Date</td>
                          <td>Ledger Name</td>
                          <td>Amount</td>
                        </>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <>
                          <td>
                            {footerData?.suggested_entry?.credit?.date
                              ? footerData?.suggested_entry?.credit?.date
                              : ""}
                          </td>
                          <td>
                            {footerData?.suggested_entry?.credit?.ledger
                              ? footerData?.suggested_entry?.credit?.ledger
                              : ""}
                          </td>
                          <td>
                            {footerData?.suggested_entry?.credit?.amount
                              ? footerData?.suggested_entry?.credit?.amount
                              : ""}
                          </td>
                        </>
                      </tr>
                      <tr>
                        <>
                          <td></td>
                          <td>
                            <b>TDS Under Section</b>
                          </td>
                          <td>
                            <b>TDS Amount</b>
                          </td>
                        </>
                      </tr>
                      {footerData?.suggested_entry?.credit?.tds &&
                        Object.keys(
                          footerData?.suggested_entry?.credit?.tds
                        )?.map((el, index) => {
                          return (
                            <tr key={index}>
                              <>
                                <td></td>
                                <td>{el}</td>
                                <td>
                                  {footerData?.suggested_entry?.credit?.tds
                                    ? footerData["suggested_entry"]["credit"][
                                        "tds"
                                      ][el]
                                    : ""}
                                </td>
                              </>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </td>
              </>
            </tr>
            <tr>
              <>
                <td></td>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <b>
                    {footerData?.suggested_entry?.debit
                      ? footerData?.suggested_entry?.debit?.total
                      : ""}
                  </b>
                </td>
                <td style={{ width: "16.66%" }}></td>
                <td style={{ width: "16.66%" }}>
                  <strong>Total</strong>
                </td>
                <td style={{ border: "none" }}>
                  <b>
                    {footerData?.suggested_entry?.credit
                      ? footerData?.suggested_entry?.credit?.total
                      : ""}
                  </b>
                </td>
              </>
            </tr>
          </tbody>
        </table>
      </div>
      <AddLedgerModal
        addLedgerData={addLedgerData}
        apiError={apiError}
        handleChange={handleChange}
        handleModalClose={handleModalClose}
        handleEditSubmit={handleEditSubmit}
        loading={loading}
        handleSelectChange={handleLedgerSelectChange}
      />
    </>
  );
};

TableEditInvoice.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  expenseList: PropTypes.array.isRequired,
  setItemId: PropTypes.func.isRequired,
  setIsChanged: PropTypes.func.isRequired,
  setEditable: PropTypes.func.isRequired,
  editable: PropTypes.object.isRequired,
  footerData: PropTypes.object,
  tdsSectionData: PropTypes.array,
  addLedgerData: PropTypes.object,
  setAddLedgerData: PropTypes.array,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  control: PropTypes.object,
  errors: PropTypes.object,
  handleModalClose: PropTypes.func,
  setApiError: PropTypes.func,
  apiError: PropTypes.object,
  ledgerListData: PropTypes.array,
  handlePopupScroll: PropTypes.func,
  ledgerListLoading: PropTypes.bool,
  tdsListData: PropTypes.array,
  handleTdsPopupScroll: PropTypes.func,
  tdsSectionListLoading: PropTypes.array,
  setLedgerPage: PropTypes.func,
  setLedgerIndexData: PropTypes.func,
  ledgerIndexData: PropTypes.number,
  setLedgerData: PropTypes.func,
  getLedgerModuleListByGroup: PropTypes.func,
  editData: PropTypes.object,
  setShowErrors: PropTypes.func,
  setItemsError: PropTypes.func,
  itemsError: PropTypes.array,
};

export default TableEditInvoice;
