/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import "../../../_components/antd.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  recurringEditInvoiceDetail,
  updateRecurringInvoice,
} from "../../../redux/actions/RecurringAction";
import { message, Spin } from "antd";
import {
  getCompanyList,
  getCustomerList,
} from "../../../redux/actions/commonApiAction";
import {
  RECURRINGINVOICE_EDIT_DETAIL_RESET,
  RECURRINGINVOICE_EDIT_RESET,
} from "../../../redux/constants/RecurringInvoiceConstant";
import {
  dayList,
  initialLedgerData,
  intervalList,
  nthWeekDayOptions,
  quarterMonthChoices,
  scheduleTypeData,
  scheduleTypes,
  weekDays,
} from "../constant";
import { formatDateYYMMDD } from "../../../utils/utils";
import AddEditRecurringModal from "./addEditRecurringModal";
import {
  getGroupList,
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../../redux/actions/LedgerModuleAction";
import { POST_LEDGERLIST_RESET } from "../../../redux/constants/ledgerListModuleConstant";
import useUpdateFooterData from "../../../customers/updateFooterData";
import useCalculateTotals from "../../../helpers/calculation";
import { customerCreation } from "../../../redux/actions/CustomerModuleAction";
import { CUSTOMER_ADD_RESET } from "../../../redux/constants/CustomerModuleCnstant";
import CustomerModal from "./customerModal";

const schema = yup.object({
  // invoice_number: yup
  //   .string()
  //   .required("Invoice number is required")
  //   .trim(),
  // interval: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required("Interval is required"),
  //   })
  //   .nullable()
  //   .required("Interval is required"),
  // day: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required("Day is required"),
  //   })
  //   .nullable()
  //   .required("Day is required"),
  customer: yup
    .number()
    .required("Customer is required")
    .typeError("Customer must be a number"),
  start_date: yup
    .date()
    .required("Start date is required")
    .nullable()
    .required("State date is required"),
  end_date: yup
    .date()
    .required("End date is required")
    .nullable()
    .required("End date is required"),
  // invoice_date: yup
  //   .date()
  //   .required("Invoice date is required")
  //   .nullable()
  //   .required("Invoice date is required"),
  // billing_period: yup
  //   .string()
  //   .required("Billing Period is required")
  //   .trim(),
});

const EditRecurringInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [recurringInvoiceData, setRecurringInvoiceData] = useState({});
  const [editError, setEditError] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(initialLedgerData);
  const [ledgerListData, setLedgerListData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");

  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [calculationData, setCalculationData] = useState({});
  const [footerData, setFooterData] = useState({});
  const [gstSection, setGstSection] = useState([]);
  const [itemsError, setItemsError] = useState(initialLedgerData);
  const [customerDetail, setCustomerDetail] = useState({});
  const [editable, setEditable] = useState({});
  const [showErrors, setShowErrors] = useState(true);
  const [incomeId, setIncomeId] = useState([]);

  const [scheduleData, setScheduleData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });
  const [editData, setEditData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });

  //customer
  const [addCustomer, setAddCustomer] = useState({});
  const customerModuleList = useSelector((state) => state?.customerModuleList);
  const { page, perPage } = customerModuleList;
  const createCustomer = useSelector((state) => state?.createCustomer);
  const [customerApiError, setCustomerApiError] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const {
    loading: createCustomerLoading,
    error: createCustomerError,
    success: createCustomerSuccess,
    data: postCustomerData,
  } = createCustomer;

  const recurringEditDetailInvoice = useSelector(
    (state) => state.editRecurringDetailInvoice
  );
  const {
    recurringEditDetail,
    loading: recurringEditDetailLoading,
  } = recurringEditDetailInvoice;
  const recurringEditInvoice = useSelector(
    (state) => state.editRecurringInvoice
  );
  const { loading, success, error } = recurringEditInvoice;

  const [messageApi, contextHolder] = message.useMessage();

  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    gstSection,
    company,
    customerDetail
  );

  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(getGroupList());
  }, []);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  const datePickerRef = {
    start_date: useRef(null),
    end_date: useRef(null),
    invoice_date: useRef(null),
  };
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (groupData?.length) {
      const fetchIncomeIds = groupData
        ?.filter((el) => el?.title === "Income" || el?.title === "Other Income")
        ?.map((el) => el?.id);
      setIncomeId(fetchIncomeIds);
    }
  }, [groupData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setIsChanged(true);
  };

  const handleCheckBox = (e, selectedOption) => {
    const { checked } = e.target;
    setIsChanged(true);
    if (checked) {
      const updatedData = { ...scheduleData };
      updatedData["weekly"] &&
        updatedData["weekly"]["days_of_week"]?.push(selectedOption);
      setScheduleData(updatedData);
      const edit_data = { ...editData };
      edit_data["weekly"] &&
        edit_data["weekly"]["days_of_week"]?.push(selectedOption);
      setEditData(edit_data);
    } else {
      setScheduleData((prevState) => ({
        ...prevState,
        weekly: {
          ...scheduleData?.weekly,
          days_of_week: scheduleData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
      setEditData((prevState) => ({
        ...prevState,
        weekly: {
          ...editData?.weekly,
          days_of_week: editData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
    }
  };

  const handleRepeatSelectChange = (selectedOption, scheduleType, id) => {
    setScheduleData((prevState) => ({
      ...prevState,
      [scheduleType]: { ...scheduleData[scheduleType], [id]: selectedOption },
    }));
    setEditData((prevState) => ({
      ...prevState,
      [scheduleType]: {
        ...editData[scheduleType],
        [id]: selectedOption?.value,
      },
    }));
    setIsChanged(true);
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    setIsChanged(true);
    const filterData = [...data];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setData(filterData);
  };

  const handleSelectChange = (id, selectedOption) => {
    setIsChanged(true);
    setEditData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleDateChange = (id, selectedDate) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setEditData((prevState) => ({ ...prevState, [id]: selectedDate }));
    setIsChanged(true);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: recurringInvoiceData,
  });

  const editLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    }
  };

  const editSubmit = (datas) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger:
        ele.ledger && typeof ele.ledger === "object"
          ? ele.ledger.value
          : ele.ledger,
      taxes: [],
    }));

    const postData = {
      invoice: {
        customer: datas?.customer,
        start_date: datas?.start_date
          ? formatDateYYMMDD(datas?.start_date)
          : "",
        end_date: datas?.end_date ? formatDateYYMMDD(datas?.end_date) : "",
        description: recurringInvoiceData?.description,
        items: ledger,
      },
      schedule: {
        schedule_type: scheduleData?.schedule_type
          ? scheduleData?.schedule_type?.value
          : "",
      },
    };

    if (editData?.schedule_type) {
      if (editData?.schedule_type === "monthly") {
        if (editData?.dayOfMonth) {
          postData["schedule"]["monthly"] = {
            day_of_month: editData["monthly"]["day_of_month"],
            repeat_every: editData["monthly"]["repeat_every"],
          };
        } else if (editData?.nthWeekday) {
          postData["schedule"]["monthly"] = {
            nth_day_of_week: editData["monthly"]["nth_day_of_week"],
            repeat_every: editData["monthly"]["repeat_every"],
            day_of_week: editData["monthly"]["day_of_week"],
          };
        }
      } else {
        postData["schedule"][editData?.schedule_type] =
          editData[editData?.schedule_type];
      }
    }

    dispatch(updateRecurringInvoice(postData, id));
    setIsChanged(false);
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const postData = {
      pan_number: addCustomer?.pan_number ? addCustomer?.pan_number : null,
      gstin_number: addCustomer?.gstin_number
        ? addCustomer?.gstin_number
        : null,
      name: addCustomer?.name ? addCustomer?.name : "",
      address: addCustomer?.address ? addCustomer?.address : "",
      zip_code: addCustomer?.zip_code ? addCustomer?.zip_code : "",
      state: addCustomer?.state ? addCustomer?.state : "",
      phone: addCustomer?.phone ? addCustomer?.phone : "",
      contact_name: addCustomer?.contact_name ? addCustomer?.contact_name : "",
      email: addCustomer?.email ? addCustomer?.email : "",
      location: addCustomer?.location,
      customer_code: addCustomer?.customer_code
        ? addCustomer?.customer_code
        : null,
    };
    dispatch(customerCreation(postData));
    setIsDisable(false);
  };

  useEffect(() => {
    setCustomerApiError(createCustomerError);
  }, [createCustomerError]);

  useEffect(() => {
    if (createCustomerSuccess && postCustomerData) {
      dispatch({ type: CUSTOMER_ADD_RESET });
      dispatch(getCustomerList());
      // dispatch(getCustomerModuleList(page, perPage));
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      setRecurringInvoiceData((prevState) => ({
        ...prevState,
        customer: postCustomerData?.id,
      }));
      setIsChanged(true);
      handleModalClose("#customer_modal");
      messageApi.open({
        type: "success",
        content: "customer created successfully",
        duration: 5,
      });
    }
  }, [createCustomerSuccess, postCustomerData]);

  useEffect(() => {
    if (recurringInvoiceData) {
      Object.keys(recurringInvoiceData).forEach((field) => {
        setValue(field, recurringInvoiceData[field]);
      });
      if (recurringInvoiceData?.invoice_date) {
        clearErrors("invoice_date");
      }
      if (recurringInvoiceData?.start_date) {
        clearErrors("start_date");
      }
      if (recurringInvoiceData?.end_date) {
        clearErrors("end_date");
      }
    }
  }, [recurringInvoiceData, setValue]);

  useEffect(() => {
    if (postLedgerSuccess && postLedgerData && incomeId?.length) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      if (incomeId?.includes(postLedgerData?.ledger_group)) {
        const updatedData = [...data];
        updatedData[ledgerIndexData]["ledger"] = postLedgerData?.id;
        setData(updatedData);
        setIsChanged(true);
        setLedgerListData([...ledgerListData, postLedgerData]);
      }
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, postLedgerData, ledgerIndexData, incomeId]);

  useEffect(() => {
    if (error?.invoice) {
      setShowErrors(error?.invoice?.items ? true : false);
      setItemsError(error?.invoice?.items ? error?.invoice?.items : null);
    } else {
      setEditError(error);
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = ["customer", "start_date", "end_date", "description"];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = ["customer", "start_date", "end_date", "description"];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch({ type: RECURRINGINVOICE_EDIT_RESET });
      dispatch({ type: RECURRINGINVOICE_EDIT_DETAIL_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("w-100"));
  }, []);

  useEffect(() => {
    dispatch(recurringEditInvoiceDetail(id));
  }, [id]);

  useEffect(() => {
    if (recurringEditDetail) {
      const filteredScheduleType = scheduleTypes?.filter(
        (el) => el?.value === recurringEditDetail?.schedule_type
      );
      setRecurringInvoiceData({
        ...recurringEditDetail,
        start_date: parseDate(recurringEditDetail?.start_date)
          ? parseDate(recurringEditDetail?.start_date)
          : "",
        end_date: parseDate(recurringEditDetail?.end_date)
          ? parseDate(recurringEditDetail?.end_date)
          : "",
        description: recurringEditDetail?.description
          ? recurringEditDetail?.description
          : "",
        customer: recurringEditDetail?.customer
          ? recurringEditDetail?.customer?.id
          : null,
      });
      setFooterData(recurringEditDetail);
      setGstSection(
        recurringEditDetail?.gst_section
          ? Object.keys(recurringEditDetail?.gst_section)
          : []
      );
      setCustomerDetail({
        customer_code: recurringEditDetail?.customer?.customer_code,
        gstin_number: recurringEditDetail?.customer?.gstin_number,
        location: recurringEditDetail?.customer?.location,
      });
      setEditData((prevState) => ({
        ...prevState,
        customer: recurringEditDetail?.customer
          ? recurringEditDetail?.customer?.id
          : null,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? filteredScheduleType[0]?.value
            : null,
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? recurringEditDetail?.schedule?.day_of_month
            : null,
          repeat_every: recurringEditDetail?.schedule?.repeat_every
            ? recurringEditDetail?.schedule?.repeat_every
            : null,
          nth_day_of_week: recurringEditDetail?.schedule?.nth_day_of_week
            ? recurringEditDetail?.schedule?.nth_day_of_week
            : null,
          day_of_week: recurringEditDetail?.schedule?.day_of_week
            ? recurringEditDetail?.schedule?.day_of_week
            : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? recurringEditDetail?.schedule?.month_of_quarter
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
        },
      }));

      const fetchWeekDayDetail = weekDays?.find(
        (el) => el?.value === recurringEditDetail?.schedule?.day_of_week
      );
      const fetchNthWeekDetail = nthWeekDayOptions?.find(
        (el) => el?.value === recurringEditDetail?.schedule?.nth_day_of_week
      );

      setScheduleData((prevState) => ({
        ...prevState,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? {
                label: filteredScheduleType[0]?.label,
                value: filteredScheduleType[0]?.value,
              }
            : null,
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? {
                label: recurringEditDetail?.schedule?.day_of_month,
                value: recurringEditDetail?.schedule?.day_of_month,
              }
            : null,
          repeat_every:
            recurringEditDetail?.schedule?.repeat_every &&
            recurringEditDetail?.schedule_type
              ? {
                  label: `${recurringEditDetail?.schedule?.repeat_every} ${
                    scheduleTypeData[recurringEditDetail?.schedule_type]
                  }`,
                  value: recurringEditDetail?.schedule?.repeat_every,
                }
              : null,
          nth_day_of_week:
            recurringEditDetail?.schedule?.nth_day_of_week && fetchNthWeekDetail
              ? {
                  label: fetchNthWeekDetail?.label,
                  value: recurringEditDetail?.schedule?.nth_day_of_week,
                }
              : null,
          day_of_week:
            recurringEditDetail?.schedule?.day_of_week && fetchWeekDayDetail
              ? {
                  label: fetchWeekDayDetail?.label,
                  value: recurringEditDetail?.schedule?.day_of_week,
                }
              : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? {
                label:
                  quarterMonthChoices[
                    recurringEditDetail?.schedule?.month_of_quarter
                  ],
                value: recurringEditDetail?.schedule?.month_of_quarter,
              }
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? {
                label: recurringEditDetail?.schedule?.month_of_year,
                value: recurringEditDetail?.schedule?.month_of_year,
              }
            : null,
        },
      }));
    }
    const ledger =
      recurringEditDetail?.items?.length &&
      recurringEditDetail?.items?.map((ele) => ({
        ...ele,
        ledger: { value: ele?.ledger?.id, label: ele?.ledger?.title },
      }));
    const postingLedgerData = recurringEditDetail?.items?.map(
      (el) => el?.ledger
    );
    postingLedgerData?.forEach((el) => {
      // Check if the object is already in ledgerListData
      const exists = ledgerListData.some((ledger) => ledger?.id === el?.id);

      // Only push if the object doesn't exist
      if (!exists) {
        ledgerListData.push(el);
      }
    });
    setData(recurringEditDetail?.items?.length ? ledger : initialLedgerData);
  }, [recurringEditDetail]);

  // useEffect(() => {
  //   if (customer?.length) {
  //     const filteredCustomerList = customer?.find(
  //       (el) => el?.id === recurringEditDetail?.customer?.id
  //     );
  //     setRecurringInvoiceData((prevState) => ({
  //       ...prevState,
  //       customer: filteredCustomerList
  //         ? {
  //             label: filteredCustomerList?.name,
  //             value: filteredCustomerList?.id,
  //           }
  //         : null,
  //     }));
  //   }
  // }, [customer, recurringEditDetail?.customer]);

  useEffect(() => {
    if (
      Object?.keys(customerDetail)?.length !== 0 &&
      !customerDetail?.gstin_number &&
      editData?.customer
    ) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [customerDetail, editData?.customer]);

  useEffect(() => {
    dispatch(getCustomerList());
  }, []);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(
        getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage, true)
      );
    }
  }, [ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (expenseList?.results) {
      const newVendors = expenseList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData?.map((vendor) => vendor?.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList

      const uniqueNewVendors = newVendors?.filter(
        (vendor) => !existingVendorIds?.has(vendor?.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerListData(combinedVendorList); // Update state with the combined list
    }
  }, [expenseList]);

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  useEffect(() => {
    if (editData?.customer) {
      const customerDetails = customer?.find(
        (el) => el?.id === editData?.customer
      );
      if (customerDetails) {
        setCustomerDetail({
          customer_code: customerDetails?.customer_code,
          gstin_number: customerDetails?.gstin_number,
          location: customerDetails?.location,
        });
      }
    }
  }, [customer, editData?.customer]);

  useEffect(() => {
    if (success) {
      dispatch({ type: RECURRINGINVOICE_EDIT_RESET });
      dispatch(recurringEditInvoiceDetail(id));
      messageApi.open({
        type: "success",
        content: "Recurring Invoice updated successfully",
        duration: 5,
      });
    }
  }, [success]);

  console.log(customerDetail, "customerDetail");

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setIsChanged(true);
    setData(updatedData);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/recurring-invoices">
                          Recurring Invoice List
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Recurring Invoice
                      </li>
                    </ul>
                  </div>
                </div>
                {recurringEditDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <AddEditRecurringModal
                      // id={""}
                      handleSubmit={handleSubmit}
                      recurringInvoiceSubmit={editSubmit}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      addError={editError}
                      handleDateChange={handleDateChange}
                      datePickerRef={datePickerRef}
                      recurringInvoiceData={recurringInvoiceData}
                      error={error}
                      setShowErrors={setShowErrors}
                      showErrors={showErrors}
                      selectRefs={selectRefs}
                      customer={customer}
                      handleSelectChange={handleSelectChange}
                      customerDetail={customerDetail}
                      scheduleData={scheduleData}
                      setScheduleData={setScheduleData}
                      handleCheckBox={handleCheckBox}
                      formattedOptions={formattedOptions}
                      handleItemChange={handleItemChange}
                      data={data}
                      setData={setData}
                      itemsError={itemsError}
                      setIsChanged={setIsChanged}
                      isChanged={isChanged}
                      loading={loading}
                      handleRepeatSelectChange={handleRepeatSelectChange}
                      setEditData={setEditData}
                      editModal={true}
                      editable={editable}
                      setEditable={setEditable}
                      handleDoubleClick={handleDoubleClick}
                      handleCellChange={handleCellChange}
                      expenseList={expenseList}
                      handleSelectCellChange={handleSelectCellChange}
                      setAddLedgerData={setAddLedgerData}
                      addLedgerData={addLedgerData}
                      apiError={apiError}
                      setApiError={setApiError}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      postLedgerLoading={postLedgerLoading}
                      handleModalClose={handleModalClose}
                      setFooterData={setFooterData}
                      footerData={footerData}
                      addLedgerSubmit={editLedgerSubmit}
                      moduleName="arModule"
                      handleLedgerPopupScroll={handleLedgerPopupScroll}
                      ledgerListLoading={ledgerListLoading}
                      ledgerListData={ledgerListData}
                      setLedgerIndexData={setLedgerIndexData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomerModal
          setAddCustomer={setAddCustomer}
          addCustomer={addCustomer}
          setCustomerApiError={setCustomerApiError}
          customerApiError={customerApiError}
          handleModalClose={handleModalClose}
          handleEditSubmit={handleAddSubmit}
          loading={createCustomerLoading}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </div>
    </>
  );
};

export default EditRecurringInvoice;
