export const GET_INVOICELIST_REQUSET = "GET_INVOICELIST_REQUEST";
export const GET_INVOICELIST_SUCCESS = "GET_INVOICELIST_SUCCESS";
export const GET_INVOICELIST_FAIL = "GET_INVOICELIST_FAIL";
export const GET_INVOICELIST_PAGE = " GET_INVOICELIST_PAGE";

export const INVOICE_CREATE_REQUEST = "INVOICE_CREATE_REQUEST";
export const INVOICE_CREATE_SUCCESS = "INVOICE_CREATE_SUCCESS";
export const INVOICE_CREATE_FAIL = "INVOICE_CREATE_FAIL";
export const INVOICE_CREATE_RESET = "INVOICE_CREATE_RESET";
export const INVOICE_EDIT_DETAIL_REQUEST = "INVOICE_EDIT_DETAIL_REQUEST";
export const INVOICE_EDIT_DETAIL_SUCCESS = "INVOICE_EDIT_DETAIL_SUCCESS";
export const INVOICE_EDIT_DETAIL_FAIL = "INVOICE_EDIT_DETAIL_FAIL";
export const INVOICE_EDIT_DETAIL_RESET = "INVOICE_EDIT_DETAIL_RESET";
export const INVOICE_EDIT_REQUEST = "INVOICE_EDIT_REQUEST";
export const INVOICE_EDIT_SUCCESS = "INVOICE_EDIT_SUCCESS";
export const INVOICE_EDIT_FAIL = "INVOICE_EDIT_FAIL";
export const INVOICE_EDIT_RESET = "INVOICE_EDIT_RESET";

export const INVOICE_SUBMIT_ERP_REQUEST = "INVOICE_SUBMIT_ERP_REQUEST";
export const INVOICE_SUBMIT_ERP_SUCCESS = "INVOICE_SUBMIT_ERP_SUCCESS";
export const INVOICE_SUBMIT_ERP_FAIL = "INVOICE_SUBMIT_ERP_FAIL";
export const INVOICE_SUBMIT_ERP_RESET = "INVOICE_SUBMIT_ERP_RESET";
