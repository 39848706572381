/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { message, Spin, Select as AntdSelect } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Controller, useForm } from "react-hook-form";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import CustomInput from "../_components/inputField/custominput";
import {
  getLedgerModuleList,
  postLedgerModuleList,
} from "../redux/actions/LedgerModuleAction";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { getVendorSearchList } from "../redux/actions/VendorAction";
import { getCustomerList } from "../redux/actions/commonApiAction";
import { transactionCreationApi } from "../redux/actions/BankModuleAction";
import { TRANSACTION_CREATE_RESET } from "../redux/constants/BankConstant";
import { formatDateYYMMDD } from "../utils/utils";
import TransactionAddEditModal from "./transactionAddEditModal";
import AddLedgerModal from "../customers/addLedgerModal";
import { POST_LEDGERLIST_RESET } from "../redux/constants/ledgerListModuleConstant";
import { VENDOR_ADD_RESET } from "../redux/constants/VendorConstant";
import VendorModal from "../sales/invoices/vendorModal";

const AllTransactionAdd = () => {
  const [menu, setMenu] = useState();
  const [addData, setAddData] = useState({
    transaction_type: null,
    vendor: null,
    withdraw: "",
    customer: null,
    deposit: "",
    description: "",
    ref_number: "",
    ledger: null,
    date: "",
    ledger: null,
    amount: "",
  });
  const [editError, setEditError] = useState({});
  const [page, setPage] = useState({ page: 1, perPage: 20 });
  const [ledgerListData, setLedgerListData] = useState([]);
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });

  //vendor
  const [addVendorData, setAddVendorData] = useState({});
  const [apiVendorError, setApiVendorError] = useState({});
  const [vendorFile, setVendorFile] = useState(null);

  const addvendor = useSelector((state) => state.addvendor);
  const {
    success: vendorSuccess,
    loading: vendorLoading,
    error: vendorError,
    data: vendorPostData,
  } = addvendor;

  const [messageApi, contextHolder] = message.useMessage();

  const [vendorList, setVendorList] = useState([]);
  const [vendorPage, setVendorPage] = useState({ page: 1, perPage: 10 });
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const transactionCreation = useSelector((state) => state.transactionCreation);
  const {
    success: transactionCreationSuccess,
    loading: transactionCreationLoading,
    error: transactionCreationError,
  } = transactionCreation;

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { data: expenseList, loading: ledgerListLoading } = ledgerList;

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  const customerList = useSelector((state) => state.customerList);
  const { customer } = customerList;

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleLedgerChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleLedgerSelectChange = (id, selectedOption) => {
    setAddLedgerData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "", message: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  useEffect(() => {
    if (transactionCreationError) {
      setEditError(transactionCreationError);
    }
  }, [transactionCreationError]);

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    const fieldOrder = [
      "date",
      "amount",
      "description",
      "transaction_type",
      "vendor",
      "with_draw",
      "customer",
      "deposit",
      "ledger",
      "ref_number",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (
        firstErrorField === "transaction_type" ||
        firstErrorField === "vendor" ||
        firstErrorField === "customer" ||
        firstErrorField === "ledger"
      ) {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  useEffect(() => {
    if (postLedgerSuccess && postLedgerData) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      setAddData((prevState) => ({ ...prevState, ledger: postLedgerData?.id }));
      setLedgerListData([...ledgerListData, postLedgerData]);
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, postLedgerData]);

  const addLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    }
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const postData = {
      amount: addData?.amount ? parseInt(addData?.amount) : null,
      description: addData?.description,
      ref_number: addData?.ref_number,
      date: addData?.date ? formatDateYYMMDD(addData?.date) : "",
      transaction_type: addData?.transaction_type
        ? addData?.transaction_type?.value
        : null,
      account: parseInt(id),
    };

    if (addData?.transaction_type?.value === "vendor_payment") {
      postData["vendor"] = addData?.vendor ? addData?.vendor : null;
      postData["withdraw"] = addData?.withdraw
        ? parseInt(addData?.withdraw)
        : null;
    } else if (addData?.transaction_type?.value === "customer_payment") {
      postData["customer"] = addData?.customer
        ? addData?.customer?.value
        : null;
      postData["deposit"] = addData?.deposit
        ? parseInt(addData?.deposit)
        : null;
    } else if (addData?.transaction_type?.value === "expense") {
      postData["ledger"] = addData?.ledger ? addData?.ledger : null;
      postData["withdraw"] = addData?.withdraw
        ? parseInt(addData?.withdraw)
        : null;
    } else if (addData?.transaction_type?.value === "other_income") {
      postData["deposit"] = addData?.deposit ? addData?.deposit : null;
    }
    dispatch(transactionCreationApi(postData));
  };

  useEffect(() => {
    if (transactionCreationSuccess) {
      dispatch({ type: TRANSACTION_CREATE_RESET });
      setAddData({
        transaction_type: null,
        vendor: null,
        withdraw: "",
        customer: null,
        deposit: "",
        description: "",
        ref_number: "",
        ledger: null,
        amount: "",
      });
      messageApi.open({
        type: "success",
        content: "Transaction Created Successfully",
        duration: 5,
      });
    }
  }, [transactionCreationSuccess]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  useEffect(() => {
    if (vendorSuccess && vendorPostData) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      setAddData((prevState) => ({
        ...prevState,
        vendor: vendorPostData ? vendorPostData?.id : null,
      }));
      setVendorList([...vendorList, vendorPostData]);
      handleModalClose("#vendor_modal");
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [vendorSuccess, vendorPostData]);

  useEffect(() => {
    if (
      (vendorPage?.page > 1 && vendorSearchList?.next) ||
      vendorPage?.page === 1
    ) {
      dispatch(getVendorSearchList("", vendorPage?.page, vendorPage?.perPage));
    }
  }, [vendorPage?.page, vendorPage?.perPage]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList.results;
      const existingVendorIds = new Set(vendorList.map((vendor) => vendor.id));
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];
      setVendorList(combinedVendorList);
    }
  }, [vendorSearchList]);

  const handleVendorPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setVendorPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage));
    }
  }, [ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (expenseList?.results) {
      const newLedgers = expenseList?.results;
      const existingLedgerIds = new Set(
        ledgerListData.map((ledger) => ledger.id)
      );
      const uniqueNewLedgers = newLedgers.filter(
        (ledger) => !existingLedgerIds.has(ledger.id)
      );
      const combinedLedgerList = [...ledgerListData, ...uniqueNewLedgers];
      setLedgerListData(combinedLedgerList);
    }
  }, [expenseList]);

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: TRANSACTION_CREATE_RESET });
    };
  }, [dispatch]);

  const handleDateChange = (id, selectedValue) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setAddData((prevState) => ({ ...prevState, [id]: selectedValue }));
  };

  const handleSelectChange = (id, selectedValue) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setAddData((prevState) => ({
      ...prevState,
      [id]: selectedValue,
    }));
    if (id === "transaction_type") {
      setAddData((prevState) => ({
        ...prevState,
        [id]: selectedValue,
        vendor: null,
        withdraw: "",
        customer: null,
        deposit: "",
        ledger: null,
      }));
    }
  };

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      console.log("Oprn>>");
      setIsModalVisible(true);
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
    });
    $("#vendor_modal").on("hide.bs.modal", () => {
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header  */}
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={`/bankdetail/${id}`}>
                          <a>All Transaction</a>
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add Transaction
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Page Header */}

                <form onSubmit={handleAddSubmit}>
                  <TransactionAddEditModal
                    addData={addData}
                    datePickerRef={datePickerRef}
                    selectRefs={selectRefs}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleCommonChange={handleCommonChange}
                    handleSelectChange={handleSelectChange}
                    editError={editError}
                    vendorList={vendorList}
                    vendorListLoading={vendorListLoading}
                    handleVendorPopupScroll={handleVendorPopupScroll}
                    customer={customer}
                    ledgerListData={ledgerListData}
                    ledgerListLoading={ledgerListLoading}
                    handleLedgerPopupScroll={handleLedgerPopupScroll}
                    addLedgerData={addLedgerData}
                    setAddLedgerData={setAddLedgerData}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    handleModalClose={handleModalClose}
                  />

                  <div class="row">
                    <div class="col">
                      <button type="submit" className="btn btn-primary">
                        {transactionCreationLoading ? <Spin /> : "Save"}
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        class="btn btn-primary cancel me-2"
                        onClick={() => history.push(`/bankdetail/${id}`)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddLedgerModal
        addLedgerData={addLedgerData}
        apiError={apiError}
        handleChange={handleLedgerChange}
        handleModalClose={handleModalClose}
        handleEditSubmit={addLedgerSubmit}
        loading={postLedgerLoading}
        handleSelectChange={handleLedgerSelectChange}
      />
      <VendorModal
        id="vendor_modal"
        title="Add Vendor"
        addData={addVendorData}
        setAddData={setAddVendorData}
        setApiError={setApiVendorError}
        apiError={apiVendorError}
        setFile={setVendorFile}
        file={vendorFile}
        handleModalClose={handleModalClose}
      />
    </>
  );
};
export default AllTransactionAdd;
