import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const DateRangePickerComponent = ({ startDate, endDate, handleDateChange }) => {
  const [range, setRange] = useState([startDate, endDate]);

  useEffect(() => {
    setRange([startDate, endDate]); // Sync state with props
  }, [startDate, endDate]);

  const handleRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setRange([start, end]);
      handleDateChange(start, end);
    } else {
      setRange([null, null]);
      handleDateChange(null, null);
    }
  };

  return (
    <RangePicker
      value={range}
      onChange={handleRangeChange}
      format="YYYY-MM-DD"
      placeholder={["Start Date", "End Date"]}
      className="datetimepicker form-control"
      allowClear
    />
  );
};

// Prop types validation
DateRangePickerComponent.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
};

export default DateRangePickerComponent;
