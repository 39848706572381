import React from "react";
import PropTypes from "prop-types";
import { teamMemberUpdateApi } from "../redux/actions/UserAction";
import UserAddEditModal from "./userAddEdit";

const UserManagementEditModal = ({
  show,
  handleSubmit,
  handleChange,
  handleRolesChange,
  rolesData,
  managerData,
  editData,
  teamMemberUpdateLoading,
  apiError,
  errors,
  // Controller,
  control,
  selectRefs,
  dispatch,
  setApiError
}) => {
  const handleEditSubmit = (data) => {
    const postData = {
      role: data?.role?.value,
      first_name: data?.first_name,
      last_name: data?.last_name,
      manager: editData?.manager ? editData?.manager?.value : null,
    };
    if (editData?.email !== data?.email) {
      postData["email"] = data?.email;
    }
    if (editData?.phone !== data?.phone) {
      postData["phone"] = data?.phone;
    }
    dispatch(teamMemberUpdateApi(editData?.id, postData));
  };
  
  const editUser=(
    <UserAddEditModal
    id={"edit_user"}
    show={show}
    title={"Edit User"}
    handleAddSubmit={handleEditSubmit}
    handleSubmit={handleSubmit}
    control={control}
    handleChange={handleChange}
    apiError={apiError}
    errors={errors}
    editData={editData}
    managerData={managerData?.results}
    handleRolesChange={handleRolesChange}
    rolesData={rolesData}
    teamMemberCreateLoading={teamMemberUpdateLoading}
    selectRefs={selectRefs}
    setApiError={setApiError}
    />
  )
  return (
    <>{editUser}</>
  );
};

UserManagementEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEditSubmit: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  rolesData: PropTypes.array,
  managerData: PropTypes.array,
  editData: PropTypes.object.isRequired,
  teamMemberUpdateLoading: PropTypes.bool,
  apiError: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  Controller: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleRolesChange: PropTypes.func.isRequired,
  selectRefs: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setApiError:PropTypes.func
};

export default UserManagementEditModal;
